
.container {
    flex: 1 1 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
}

.title {
    font-style: initial;
    font-display: initial;
    text-decoration: none;
    padding: 10px;    
}